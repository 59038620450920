import React, { useContext } from "react";
import { AppContext } from "../../../../../AppContext";
import { KeystoreInformation } from "../../components/KeystoreInformation";

const Keynest = () => {
  const {
    booking: {
      contact_number: contactNumber,
      guest_key: guestKey,
      property
    }
  } = useContext(AppContext);

  const location = property?.home_info?.location;

  return (
    <>
      <p>
        Access to the home can be obtained from a keystore{" "}
        {guestKey?.keystore_location?.name} located at{" "}
        {guestKey?.keystore_location?.street_address}. Go to
        the counter at the keynest location, mention you are
        picking up keys, and provide the code
        {guestKey?.collection_code ? (
          <> - {guestKey?.collection_code}.</>
        ) : (
          "."
        )}
      </p>

      {!guestKey?.collection_code && (
        <p>
          You will receive your keynest code 48 hours before
          your arrival date.
        </p>
      )}

      <p>{location?.access_type_notes}</p>

      <KeystoreInformation
        contactNumber={contactNumber}
        guestKey={guestKey}
        location={location}
      />
    </>
  );
};

export default Keynest;
