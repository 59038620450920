import "./PropertyAccess.scss";
import React, { useContext } from "react";
import { AppContext } from "AppContext";
import AccessInstructionsUnavailable from "./Access/components/AccessInstructionsUnavailable";
import stepsByAccessType from "./Access/stepsByAccessType";

export const PropertyAccess = () => {
  const {
    booking: {
      property,
      property_access_instructions_available:
        propertyAccessInstructionsAvailable
    }
  } = useContext(AppContext);

  if (!propertyAccessInstructionsAvailable) {
    return <AccessInstructionsUnavailable />;
  }

  return (
    <section className="PropertyAccess">
      <h2 className="header">Getting there</h2>

      <ol className="PropertyAccess__list">
        {
          stepsByAccessType[
            property?.home_info?.location?.access_type
          ]
        }
      </ol>
    </section>
  );
};
