import "./DetailsHeader.scss";

import { useAppContext } from "AppContext";

export const DetailsHeader = () => {
  const { booking } = useAppContext();

  if (booking && booking.property) {
    const { property, terms_accepted } = booking;
    const {
      city,
      full_address: fullAddress,
      home_info: homeInfo,
      locality: { name: localityName }
    } = property;

    const floorInfo = homeInfo?.unit_floor
      ? `, ${homeInfo?.unit_floor} floor`
      : "";

    return (
      <section className={`DetailsHeader City ${city}`}>
        <h1 className="main-header">
          {terms_accepted
            ? `Your stay at ${fullAddress} ${floorInfo}`
            : `Your stay in ${localityName}`}
        </h1>
      </section>
    );
  }

  return null;
};
