import { Accordion } from "components/Accordion";
import { ReactComponent as BinIcon } from "assets/icons/bin.svg";
import { ReactComponent as LockIcon } from "assets/icons/lock.svg";
import { ReactComponent as RecycleIcon } from "assets/icons/recycle.svg";
import { ReactComponent as SmokingIcon } from "assets/icons/smoking.svg";
import { ReactComponent as LiftIcon } from "assets/icons/elevator.svg";
import { ReactComponent as StairsIcon } from "assets/icons/stairs.svg";
import { checkIfAllFalse } from "utils/helpers";

export const GettingAround = ({ property }) => {
  const homeInfo = property?.home_info;
  if (!homeInfo) {
    return null;
  }

  const {
    lift_available: liftAvailable,
    smoking_notes: smokingNotes,
    stairs_notes: stairsNotes
  } = homeInfo;

  const lockUpInstructions =
    homeInfo.location?.lock_up_instructions;
  const wasteInstructions =
    homeInfo.cleaning?.waste_instructions;
  const recyclingInstructions =
    homeInfo.cleaning?.recycling_instructions;

  if (
    checkIfAllFalse([
      liftAvailable,
      stairsNotes,
      lockUpInstructions,
      wasteInstructions,
      recyclingInstructions,
      smokingNotes
    ])
  ) {
    return null;
  }

  return (
    <Accordion title="Getting around property">
      <ul className="PropertyInformation__list">
        {lockUpInstructions && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <LockIcon className="PropertyInformation__icon" />
              Lockup instructions
            </span>

            <span className="PropertyInformation__text">
              {lockUpInstructions}
            </span>
          </li>
        )}

        {wasteInstructions && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <BinIcon className="PropertyInformation__icon" />
              Waste
            </span>

            <span className="PropertyInformation__text">
              {wasteInstructions}
            </span>
          </li>
        )}

        {recyclingInstructions && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <RecycleIcon className="PropertyInformation__icon" />
              Recycling
            </span>

            <span className="PropertyInformation__text">
              {recyclingInstructions}
            </span>
          </li>
        )}

        {smokingNotes && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <SmokingIcon className="PropertyInformation__icon" />
              Smoking
            </span>

            <span className="PropertyInformation__text">
              {smokingNotes}
            </span>
          </li>
        )}

        {liftAvailable !== null && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <LiftIcon className="PropertyInformation__icon" />
              Lift available
            </span>

            <span className="PropertyInformation__text">
              {liftAvailable ? "Yes" : "No"}
            </span>
          </li>
        )}

        {stairsNotes && (
          <li className="PropertyInformation__item">
            <span className="PropertyInformation__heading small-header">
              <StairsIcon className="PropertyInformation__icon" />
              Stairs
            </span>

            <span className="PropertyInformation__text">
              {stairsNotes}
            </span>
          </li>
        )}
      </ul>
    </Accordion>
  );
};
